import 'select2/dist/js/select2.js';
import 'select2/dist/js/i18n/fr';

const defaultOptions = {
  theme: 'bootstrap-5',
  language: 'fr',
  width: $(this).data('width')
    ? $(this).data('width')
    : $(this).hasClass('w-100')
    ? '100%'
    : 'style',
};
$('.select2').select2(defaultOptions);

$('.select2-sm').select2(
  Object.assign(defaultOptions, {
    selectionCssClass: 'select2--small',
    dropdownCssClass: 'select2--small',
  })
);

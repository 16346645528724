/*
 * Welcome to your app's main JavaScript file!
 *
 * We recommend including the built version of this JavaScript file
 * (and its CSS file) in your base layout (base.html.twig).
 */

// any CSS you import will output into a single css file (app.css in this case)
import './styles/app.scss';

// start the Stimulus application
import './bootstrap';

import '@js/twbs.js';
import '@js/highlight.js';
import '@js/select2.js';

// async function fetchApi () {
//     const r = await fetch("https://jsonplaceholder.typicode.com/todos?_limit=3").then(r => r.json()) console.log(r[0]['title'])
// }

// fetchApi()